* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Raleway, 'PT Sans', sans-serif;
}

.responsive {
  width: 100%;
  height: auto;
}
